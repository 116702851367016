import { browserTracingIntegration } from '@sentry/browser';
import { reactRouterV6BrowserTracingIntegration, init as sentryInit } from '@sentry/react';
import { StytchB2BProvider } from '@stytch/react/dist/b2b';
import { StytchB2BUIClient } from '@stytch/vanilla-js/b2b';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { StrictMode, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { SWRConfig } from 'swr';

import App from '@/app/App';
import { sessionDurationMinutes } from '@/constants/numbers';
import '@/helper/highcharts-tooltip-extension';
import { getLDContext } from '@/helper/launchdarkly';
import { updateSentryTags } from '@/helper/sentry';
import { createUserContext } from '@/providers/User';

import styles from './index.module.scss';
import './styles/colors.light.css';

document.body.classList.add(styles.app);

const ENV = window.ENV || 'local';
const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN || '';

const stytch = new StytchB2BUIClient(import.meta.env.VITE_STYTCH_PUBLIC_TOKEN || '', {
  cookieOptions: {
    jwtCookieName: 'c99_session_jwt',
    istCookieName: 'c99_intermediate_session_token',
    opaqueTokenCookieName: 'c99_session',
  },
});

sentryInit({
  dsn: SENTRY_DSN,
  environment: ENV,
  integrations: [
    browserTracingIntegration(),
    reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracePropagationTargets: [import.meta.env.VITE_SOL_HOST],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement, {
  identifierPrefix: 'c99-',
});

(async () => {
  let user = null;
  try {
    user = await stytch.session.authenticate({
      session_duration_minutes: sessionDurationMinutes,
    });
  } catch {
    // no logged-in user yet
  }

  let context = undefined;
  if (user) {
    const userContext = createUserContext(user.member, user.organization);
    context = getLDContext(userContext);
    updateSentryTags(userContext);
    window.USER_ID = userContext.id;
  }

  const LDProvider = await asyncWithLDProvider({
    clientSideID: import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID!,
    options: { streaming: true },
    context,
    timeout: 5,
  });

  root.render(
    <StrictMode>
      <SWRConfig
        value={{
          revalidateIfStale: true,
          revalidateOnFocus: true,
          revalidateOnReconnect: true,
          shouldRetryOnError: true,
          focusThrottleInterval: 720_000,
          dedupingInterval: 10_000,
          errorRetryInterval: 5_000,
        }}
      >
        <StytchB2BProvider stytch={stytch}>
          <LDProvider>
            <App />
          </LDProvider>
        </StytchB2BProvider>
      </SWRConfig>
    </StrictMode>,
  );
})();
